import * as React from 'react';
import AppBar from '@mui/material/AppBar';

import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import {styled} from "@mui/system";
import Container from "@mui/material/Container";
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import {StyledHomeIcon, StyledLockOpenIcon, StyledLogoutIcon} from "../styles/icon";
import Button from "@mui/material/Button";
import {StyledLink} from "../styles/link";


const StyledContainer = styled(Container)(({theme}) => ({
    height: '5vh',
    flex: 1,
    marginBottom: '1rem',
    width: '100%',
}));

const MainAppBar = (props) => {
    const [drawer, setDrawer] = React.useState(false);

    const toggleDrawer = (open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        setDrawer(open);
    };

    const handleLogout = () => {
        localStorage.removeItem('access_token');
        localStorage.removeItem('refresh_token');
    };

    return (
        <StyledContainer>
            <AppBar position="static">
                <Toolbar>
                    <IconButton
                        size="large"
                        edge="start"
                        color="inherit"
                        aria-label="menu"
                        sx={{mr: 2}}
                        onClick={toggleDrawer(true)}
                    >
                        <MenuIcon/>
                    </IconButton>
                    <Typography variant="h6" component="div" sx={{flexGrow: 1}}>
                        {props.currentPage}
                    </Typography>
                    <StyledLink href="/premium">
                        <Button
                            variant="contained"
                            color="secondary"
                            style={{
                                display: "flex",
                                alignItems: "center",
                                border: "1px solid white",
                            }}
                        >
                            <img
                                src="/suberoak.svg"
                                alt="Suberoak Logo"
                                width={30}
                                height={30}
                                style={{ marginRight: "10px" }} // Add margin between the image and text
                            />
                            <Typography>Subscribe</Typography>
                        </Button>
                    </StyledLink>
                </Toolbar>
            </AppBar>

            <Drawer
                open={drawer}
                onClose={toggleDrawer(false)}
            >
                <Box
                    sx={{width: 250}}
                    role="presentation"
                    onClick={toggleDrawer(false)}
                    onKeyDown={toggleDrawer(false)}
                >
                    <List>
                        <ListItem disablePadding>
                            <ListItemButton to="/home">
                                <ListItemIcon>
                                    <StyledHomeIcon/>
                                </ListItemIcon>
                                <ListItemText primary="Dashboard"/>
                            </ListItemButton>
                        </ListItem>
                        <ListItem disablePadding>
                            <ListItemButton to="/premium">
                                <ListItemIcon>
                                    <StyledLockOpenIcon/>
                                </ListItemIcon>
                                <ListItemText primary="Subscribe"/>
                            </ListItemButton>
                        </ListItem>
                    </List>
                    <Divider/>
                    <List>
                        <ListItem disablePadding>
                            <ListItemButton onClick={handleLogout} to="/">
                                <ListItemIcon>
                                    <StyledLogoutIcon/>
                                </ListItemIcon>
                                <ListItemText primary="Logout"/>
                            </ListItemButton>
                        </ListItem>
                    </List>
                </Box>
            </Drawer>
        </StyledContainer>
    );
}

export default MainAppBar;
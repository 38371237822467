import React from 'react';
import DateTimeDisplay from './DateTimeDisplay';
import {useCountdown} from "../../hooks/countdown";

const ExpiredNotice = () => {
  return (
    <div className="expired-notice">
      <span>New Reports Released</span>
      <p>New reports were just created, go check them out!</p>
    </div>
  );
};

const ShowCounter = ({ days, hours, minutes, seconds, onMouseEnter, onMouseLeave, owns, hasPopup }) => {
  const danger = (days < 1 && hours <= 1)
  return (
    <div className="show-counter" onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave} aria-owns={owns} aria-haspopup={hasPopup}>
        <DateTimeDisplay value={days} type={'Days'} isDanger={danger} />
        <p>:</p>
        <DateTimeDisplay value={hours} type={'Hours'} isDanger={danger} />
        <p>:</p>
        <DateTimeDisplay value={minutes} type={'Mins'} isDanger={danger} />
        <p>:</p>
        <DateTimeDisplay value={seconds} type={'Seconds'} isDanger={danger} />
    </div>
  );
};

const CountdownTimer = ({ targetDate, onMouseEnter, onMouseLeave, owns, hasPopup }) => {
  const [days, hours, minutes, seconds] = useCountdown(targetDate);

  if (days === 6 && hours >= 23) {
    return <ExpiredNotice />;
  } else {
    return (
      <ShowCounter
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          owns={owns}
          hasPopup={hasPopup}
          days={days}
          hours={hours}
          minutes={minutes}
          seconds={seconds}
      />
    );
  }
};

export default CountdownTimer;

import * as React from 'react';
import {useEffect, useState} from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import StarIcon from '@mui/icons-material/StarBorder';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import {makeAuthenticatedRequest} from "../../utils/authenticated_request";
import {StyledCardHeader} from "../styles/card";
import MainAppBar from "../navigation/MainAppBar";
import {StyledButton} from "../styles/button";

const tiers = [
  {
    title: 'Free',
    lookupKey: 'FREE',
    price: '0',
    description: [
      '1 weekly report',
      '+ Average prices',
      '+ Property types',
    ],
    buttonText: 'Cancel Subscription',
    buttonVariant: 'outlined',
  },
  {
    title: 'Premium',
    lookupKey: 'PREMIUM',
    subheader: 'Most popular',
    price: '10',
    description: [
      '5 weekly reports',
      '+ All free tier insights',
      '+ Top performing branches',
      '+ Offers',
    ],
    buttonText: 'Update Subscription',
    buttonVariant: 'contained',
  },
  {
    title: 'Pro',
    lookupKey: 'PRO',
    price: '15',
    description: [
      'Unlimited reports',
      '+ All premium tier insights',
      '+ Individual property sales',
    ],
    buttonText: 'Upgrade Subscription',
    buttonVariant: 'outlined',
  },
];

const Premium = () => {
  const [user, setUser] = useState({});

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const response = await makeAuthenticatedRequest(
          `${process.env.REACT_APP_API_HOST}/user/me/`
        );
        if (response.ok) {
          const jsonData = await response.json();
          setUser(jsonData);
        } else {
          // Handle error if the response is not ok
          console.error('Error:', response.status);
        }
      } catch (error) {
        // Handle error if the request fails
        console.error('Error:', error);
      }
    };

    fetchUser();
  }, []);

  const handleSubmitCheckout = async (tier) => {
    const payload = {
      tier: tier,
    };
    try {
      // const response = await makeAuthenticatedRequest(
      //   `${process.env.REACT_APP_API_HOST}/user/${user.id}/`,
      //   'PATCH',
      //   payload
      // );
      const response = await makeAuthenticatedRequest(
        `${process.env.REACT_APP_API_HOST}/user/checkout/`,
        'POST',
        payload
      );
      if (response.ok) {
        const jsonData = await response.json();
        window.location.href = jsonData.checkout_url
      } else {
        // Handle error if the response is not ok
        console.error('Error:', response.status);
      }
    } catch (error) {
      // Handle error if the request fails
      console.error('Error:', error);
    }
  };

  const handleCustomerPortal = async () => {
    try {
      const response = await makeAuthenticatedRequest(
        `${process.env.REACT_APP_API_HOST}/user/customer_portal/`,
        'GET',
      );
      if (response.ok) {
        const jsonData = await response.json();
        window.location.href = jsonData.customer_portal_url
      } else {
        // Handle error if the response is not ok
        console.error('Error:', response.status);
      }
    } catch (error) {
      // Handle error if the request fails
      console.error('Error:', error);
    }
  };

  const getButtonText = (tier) => {
    let buttonText;
    if (user.tier === tier.lookupKey) {
      buttonText = "CURRENT PACKAGE";
    }
    else if (user.tier === "FREE") {
        buttonText = "GET STARTED";
    }
    else {
      buttonText = tier.buttonText;
    }

    return buttonText;
  }

  return (
    <React.Fragment>
              <MainAppBar currentPage="Subscribe"/>

      <Container maxWidth="sm" component="main" sx={{ pt: 8, pb: 6 }}>
        <Typography
          component="h4"
          variant="h4"
          align="center"
          color="text.primary"
        >
          Looking for more?
        </Typography>
        <Typography variant="h6" align="center" color="text.secondary" component="p">
            Check out what extra features you can get with the various pricing tiers that we offer
        </Typography>
      </Container>
      <Container maxWidth="md" component="main">
        <Grid container spacing={5} alignItems="flex-end">
          {tiers.map((tier) => (
            <Grid
              item
              key={tier.lookupKey}
              xs={12}
              sm={tier.lookupKey === 'PREMIUM' ? 12 : 6}
              md={4}
            >
              <Card>
                <StyledCardHeader
                  title={tier.title}
                  subheader={tier.subheader}
                  titleTypographyProps={{ align: 'center' }}
                  action={tier.lookupKey === 'PREMIUM' ? <StarIcon /> : null}
                  subheaderTypographyProps={{
                    align: 'center',
                  }}
                />
                <CardContent>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'baseline',
                      mb: 2,
                    }}
                  >
                    <Typography component="h2" variant="h3" color="text.primary">
                      €{tier.price}
                    </Typography>
                    <Typography variant="h6" color="text.secondary">
                      /mo
                    </Typography>
                  </Box>
                  <ul>
                    {tier.description.map((line) => (
                      <Typography
                        component="li"
                        variant="subtitle1"
                        align="center"
                        key={line}
                      >
                        {line}
                      </Typography>
                    ))}
                  </ul>
                </CardContent>
                <CardActions>
                  <StyledButton fullWidth variant={tier.buttonVariant} disabled={tier.lookupKey === user.tier} onClick={() => user.tier === "FREE" ? handleSubmitCheckout(tier.lookupKey) : handleCustomerPortal()}>
                    {getButtonText(tier)}
                  </StyledButton>
                </CardActions>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>
    </React.Fragment>
  );
}

export default Premium;

import {styled} from "@mui/system";
import Container from "@mui/material/Container";

export const StyledContainer = styled(Container)({
  '&.mapContainer': {
    height: '35vh',
    flex: 1,
    marginBottom: '1rem',
    width: '100%',
  },
});

export const StyledAccordionContainer = styled('div')(({ theme }) => ({
  flex: 1,
  overflow: 'auto',
  padding: theme.spacing(2),
  maxHeight: '20vh',
}));

export const StyledMapContainer = styled('div')(({ theme }) => ({
  height: '50vh',
  background: "#ffffff",
}));

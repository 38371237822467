import React, {useState} from 'react';
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import theme from "../../theme";
import Paper from "@mui/material/Paper";
import Copyright from "./Copyright";
import {Dialog, DialogActions, DialogContent, DialogTitle} from "@mui/material";


const sectionStyle = {
  marginBottom: '2rem',
  // width: '100vw',
  marginLeft: '1rem',
  marginRight: '1rem',
  padding: '1rem',
  backgroundColor: theme.palette.secondary.main,
};

const sectionTitleStyle = {
  marginBottom: '1rem',
  paddingTop: '1rem',
  paddingBottom: '1rem',
  backgroundColor: theme.palette.primary.main,
};

const LandingBody = () => {
    const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div style={{ textAlign: 'center', padding: '1rem', backgroundColor: theme.palette.secondary.main, width: '100vw' }}>
      <Paper style={sectionStyle}>
        <Paper>
        <Typography color="white" variant="h6" style={sectionTitleStyle}>
          <b>Unlock the Power of Real Estate Insights with Suber Oak</b>
        </Typography>
          </Paper>
        <Typography color="white" variant="body1">
          Are you looking to stay ahead of the curve in the dynamic Irish real estate market? At Suber Oak, we understand the challenges you face when trying to navigate the ever-changing landscape of property buying, selling, or renting. That's why we've created a cutting-edge solution tailored just for you.
        </Typography>
      </Paper>

      <Paper style={sectionStyle}>
        <Paper>
          <Typography color="white" variant="h6" style={sectionTitleStyle}>
            <b>The Problem</b>
          </Typography>
        </Paper>
        <Typography color="white" variant="body1">
          The Irish real estate market is known for its complexity, with data scattered across multiple sources. Keeping up with the latest trends, prices, and property types can be overwhelming. Missing out on crucial information can lead to costly decisions and missed opportunities.
        </Typography>
      </Paper>

      <Paper style={sectionStyle}>
        <Paper>
          <Typography color="white" variant="h6" style={sectionTitleStyle}>
            <b>Our Solution</b>
          </Typography>
        </Paper>
        <Typography color="white" variant="body1">
          Enter Suber Oak, your key to unlocking the insights you need. We've harnessed the power of data by meticulously scraping information from various sources, bringing it all under one roof. Our mission is to simplify your real estate journey, providing you with clear, actionable information.
        </Typography>
      </Paper>

      <Paper style={sectionStyle}>
        <Paper>
          <Typography color="white" variant="h6" style={sectionTitleStyle}>
            <b>What We Offer</b>
          </Typography>
        </Paper>
        <Typography color="white" variant="body1" style={{textAlign: 'left'}}>
          <b>Customized Reports</b>: Tailor your reports to focus on the city, county, and market segment that matters to you, whether it's buying, selling, or renting.
          <br />
          <b>Weekly Updates</b>: Stay up-to-date with the latest information. Our reports are delivered to your inbox every Saturday, ensuring you're always in the know.
          <br />
          <b>In-Depth Insights</b>: Gain valuable insights, including average prices, price per square meter, bedroom/bathroom-specific pricing, property type distribution, auctioneer listings, and recent property sales.
          <br />
          <b>Example Report</b>: Take a sneak peek at what our reports offer with our <Button variant="contained" style={{border: '1px solid white'}} size="small" onClick={handleOpen}>sample report</Button>
        </Typography>
      </Paper>

      <Paper style={sectionStyle}>
        <Paper>
          <Typography color="white" variant="h6" style={sectionTitleStyle}>
            <b>Why Choose Suber Oak?</b>
          </Typography>
        </Paper>
        <Typography color="white" variant="body1" style={{textAlign: 'left'}}>
          <b>Accuracy</b>: Our data is rigorously collected and updated, so you can trust its precision.
          <br />
          <b>Convenience</b>: Say goodbye to endless web searches and data compilation. We've done the work for you.
          <br />
          <b>Empowerment</b>: Make informed decisions with data-driven insights, ensuring your success in the real estate market.
        </Typography>
      </Paper>

      <Paper style={sectionStyle}>
        <Paper>
          <Typography color="white" variant="h6" style={sectionTitleStyle}>
            <b>Join Us Today</b>
          </Typography>
        </Paper>
        <Typography color="white" variant="body1">
          Don't miss out on the opportunity to revolutionize your real estate journey. Sign up for Suber Oak today and gain access to the most comprehensive real estate reports in Ireland. Let us be your partner in success!
        </Typography>
      </Paper>
      <Copyright sx={{ mt: 10, mb: 2 }} />


      <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
        <DialogTitle>Sample Report</DialogTitle>
        <DialogContent style={{ height: '100vh', overflowY: 'auto' }}>
          <embed src="/pro_city_cork_buy_2023-10-07.pdf" type="application/pdf" width="100%" height="100%" />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default LandingBody;

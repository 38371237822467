import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import NotFound from './components/navigation/NotFound';
import ReportHistory from "./components/dashboard/ReportHistory";
import ReportHistories from "./components/dashboard/ReportHistories";
import Home from "./components/dashboard/Home";
import Reports from "./components/dashboard/Reports";
import SubscribeReport from "./components/dashboard/SubscribeReport";
import SignIn from "./components/authentication/SignIn";
import SignUp from "./components/authentication/SignUp";
import Premium from "./components/payments/Premium";
import Landing from "./components/landing/Landing";
import ForgotPassword from "./components/authentication/ForgotPassword";

const App = () => {
  return (
    <Router>
      <Routes>
          <Route path="/" element={<Landing />} />
          <Route path="/home" element={<Home />} />
          <Route path="/reports" element={<Reports />} />
          <Route path="/sign-in" element={<SignIn />} />
          <Route path="/sign-up" element={<SignUp />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/subscribe-report" element={<SubscribeReport />} />
          <Route path="/report-histories/" element={<ReportHistories />} />
          <Route path="/report-history/:uuid" element={<ReportHistory />} />
          <Route path="/premium" element={<Premium />} />
          <Route path="*" element={<NotFound />} />
      </Routes>
    </Router>
  );
};

export default App;

import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import {styled} from "@mui/system";
import AccordionSummary from "@mui/material/AccordionSummary";
import Container from "@mui/material/Container";

export const StyledAccordion = styled(Accordion)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  background: theme.palette.primary.main,
    color: theme.palette.secondary.contrastText,

}));

export const StyledAccordionSummary = styled(AccordionSummary)(({ theme }) => ({
  color: theme.palette.secondary.contrastText,
  justifyContent: 'space-between',
}));

export const AccordionSummaryText = styled('div')({
  display: 'flex',
  alignItems: 'center',
});

export const AccordionDetailsContainer = styled(AccordionDetails)({
  display: 'flex',
  flexDirection: 'column',
  background: "#ffffff"
});
import * as React from 'react';
import { useEffect } from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import theme from "../../theme";

import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import LandingBody from "./LandingBody";
import Button from "@mui/material/Button";
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';
import {Link as RouterLink} from 'react-router-dom';


const Landing = () => {
  useEffect(() => {
    if (localStorage.getItem('access_token')) {
      window.location.href = '/home';
    };
  }, []);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
        justifyContent: 'space-between',
      }}
    >
      <Container component="main">
        <CssBaseline />
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
             <Container maxWidth="md" sx={{
            paddingTop: '10rem',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
                 height: '100vh',

          }}>
                                                                              <div style={{ position: 'absolute', top: '1rem', right: '1rem' }}>
        <Button component={RouterLink} to="/sign-in" variant="contained" color="primary">
          Log In
        </Button>
      </div>


       <div style={{ position: 'fixed', bottom: '4rem', margin: '0 auto', zIndex: 1000 }}>
        <Button component={RouterLink} to="/sign-up" variant="contained" color="primary" size="large" style={{border: '1px solid white'}}>
          Sign Up
        </Button>
      </div>
                           <img
            src="/suberoak.svg"
            alt="Suberoak Logo"
            width={175}
            height={175}
          />

          <Typography component="h3" variant="h1">
            Suber Oak
          </Typography>
          <Typography variant="h5" color="textSecondary" align="center">
            Weekly <span style={{ color: theme.palette.primary.main }}>property market reports</span>, right to your <span style={{ color: theme.palette.primary.main }}>inbox</span>
          </Typography>
                 <KeyboardDoubleArrowDownIcon style={{fill: theme.palette.primary.main, position: "absolute", bottom: "1rem"}}/>

             </Container>
            <Box>
              <LandingBody />
          </Box>
        </Box>
      </Container>
    </Box>
  );
}

export default Landing;

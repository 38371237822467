import React, { useEffect, useState } from 'react';
import {makeAuthenticatedRequest} from "./authenticated_request";

const ReportDownload = ({ reportId }) => {
  const [loading, setLoading] = useState(true);
  const [pdfUrl, setPdfUrl] = useState(null);

  useEffect(() => {
    const fetchPdf = async () => {
      try {
        const response = await makeAuthenticatedRequest(`${process.env.REACT_APP_API_HOST}/report_history/${reportId}/view_report/`)
        if (response.status === 200 && response.headers.get('content-type') === 'application/pdf') {
          const blob = await response.blob();
          const pdfUrl = window.URL.createObjectURL(blob);
          setPdfUrl(pdfUrl);
          setLoading(false);
        } else {
          console.error('Error fetching report:', response.status, response.statusText);
          setLoading(false);
        }
      } catch (error) {
        console.error('Error fetching report:', error);
        setLoading(false);
      }
    };

    fetchPdf();
  }, [reportId]);

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div style={{"height": "100vh"}}>
      {pdfUrl ? (
        <embed src={pdfUrl} type="application/pdf" width="100%" height="100%" />
      ) : (
        <div>Failed to load the PDF</div>
      )}
    </div>
  );
};

export default ReportDownload;
import React from 'react';
import {StyledButton} from "../styles/button";

const Logout = () => {
  const handleLogout = () => {
    localStorage.removeItem('access_token');
    localStorage.removeItem('refresh_token');

    window.location.href = '/';
  };

  return (
    <StyledButton onClick={handleLogout}>Logout</StyledButton>
  );
};

export default Logout;

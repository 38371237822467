import { createTheme } from '@mui/material/styles';
import { purple } from '@mui/material/colors';

const theme = createTheme({
  palette: {
    primary: {
      main: '#D90812',
      mainGradient: 'linear-gradient(51.69deg, #A600FF 0%, #8528CE 100%)',
      contrastText: "#ffffff"

    },
    secondary: {
      main: '#B2060E',
      contrastText: "#ffffff"
    },
  },
  components: {
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          fill: '#ffffff',
        },
      },
    },
  },
});

export default theme;

import React from 'react';
import { useParams } from 'react-router-dom';
import ReportDownload from "../../utils/report_download";

const ReportHistory = () => {
  const { uuid } = useParams(); // Access the UUID from URL parameter

  return (
    <div>
        <ReportDownload reportId={uuid} />
    </div>
  );
};

export default ReportHistory;

// Function to make authenticated requests
export async function makeAuthenticatedRequest(url, method = 'GET', body = null) {
  const token = localStorage.getItem('access_token');
  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${token}`,
  };

  const requestOptions = {
    method,
    headers,
    body: body ? JSON.stringify(body) : null,
  };

  const response = await fetch(url, requestOptions);

  if (response.status === 401) {
    // Token has expired, handle token refresh
    const refreshToken = localStorage.getItem('refresh_token');
    const refreshResponse = await fetch(`${process.env.REACT_APP_API_HOST}/token/refresh`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ refresh: refreshToken }),
    });
    if (!refreshResponse.ok) {
      window.location.href = '/sign-in';
    }

    if (refreshResponse.ok) {
      const { access: newAccessToken } = await refreshResponse.json();

      // Update the access token in localStorage
      localStorage.setItem('access_token', newAccessToken);

      // Retry the request with the new access token
      return makeAuthenticatedRequest(url, method, body);
    } else {
      // Handle refresh token failure (e.g., logout user)
      // ...
    }
  }

  // Handle other response statuses and return the result
  // ...
  return response;
}
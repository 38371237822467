import {styled} from "@mui/system";
import LogoutIcon from "@mui/icons-material/Logout";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import HomeIcon from "@mui/icons-material/Home";

export const StyledLogoutIcon = styled(LogoutIcon)(({ theme }) =>({
  fill: theme.palette.primary.main,
}));

export const StyledLockOpenIcon = styled(LockOpenIcon)(({ theme }) =>({
  fill: theme.palette.primary.main,
}));

export const StyledHomeIcon = styled(HomeIcon)(({ theme }) =>({
  fill: theme.palette.primary.main,
}));
import React, {useEffect, useState} from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DeleteIcon from '@mui/icons-material/Delete';
import IrelandMap from "./IrelandMap";
import {makeAuthenticatedRequest} from "../../utils/authenticated_request";
import {Link} from "react-router-dom";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import SubscribeReport from "./SubscribeReport";
import MainAppBar from "../navigation/MainAppBar";
import {StyledAccordionContainer, StyledContainer} from "../styles/container";
import {StyledChip} from "../styles/chip";
import {AccordionDetailsContainer, StyledAccordion, StyledAccordionSummary} from "../styles/accordion";
import {StyledButton, StyledIconButton} from "../styles/button";
import {RootContainer} from "../styles/root";
import CountdownTimer from "./CountdownTimer";
import Checkbox from "@mui/material/Checkbox";
import ReportCount from "./ReportCount";
import {Popover} from "@mui/material";

const Home = () => {
  const [reports, setReports] = useState([]);
  const [reportHistories, setReportHistories] = useState([]);
  const [expanded, setExpanded] = React.useState(false);
  const [reportCount, setReportCount] = React.useState(0);
  const [reportTotal, setReportTotal] = React.useState(0);
  const [activeReportsPopover, setActiveReportsPopover] = React.useState(null);
  const [countdownPopover, setCountdownPopover] = React.useState(null);
  const [checkboxPopover, setCheckboxPopover] = React.useState(null);

  const activeReportsOpen = Boolean(activeReportsPopover);
  const countdownOpen = Boolean(countdownPopover);
  const checkboxOpen = Boolean(checkboxPopover);



  const handleActiveReportsPopoverOpen = (event) => {
    setActiveReportsPopover(event.currentTarget);
  };

  const handleActiveReportsPopoverClose = () => {
    setActiveReportsPopover(null);
  };

  const handleCountdownPopoverOpen = (event) => {
    setCountdownPopover(event.currentTarget);
  };

  const handleCountdownPopoverClose = () => {
    setCountdownPopover(null);
  };

  const handleCheckboxPopoverOpen = (event) => {
    setCheckboxPopover(event.currentTarget);
  };

  const handleCheckboxPopoverClose = () => {
    setCheckboxPopover(null);
  };

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  useEffect(() => {
    const fetchReportHistories = async () => {
      try {
        const response = await makeAuthenticatedRequest(`${process.env.REACT_APP_API_HOST}/report_history/`);
        if (response.ok) {
          const jsonData = await response.json();
          setReportHistories(jsonData.results);
        } else {
          console.error('Error:', response.status);
        }
      } catch (error) {
        console.error('Error:', error);
      }
    };
    fetchReportHistories();
  }, []);

  const fetchReports = async () => {
    try {
      const response = await makeAuthenticatedRequest(`${process.env.REACT_APP_API_HOST}/report/`);
      if (response.ok) {
        const jsonData = await response.json();
        setReports(jsonData);
        setReportCount(jsonData.filter(obj => obj.active === true).length)
      } else {
        console.error('Error:', response.status);
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  useEffect(() => {
    fetchReports();
  }, []);

  const handleUnsubscribe = async () => {
    try {
      const payload = {
        active: expanded.active,
      };
      const response = await makeAuthenticatedRequest(
        `${process.env.REACT_APP_API_HOST}/report/${expanded.uuid}/unsubscribe/`,
        'POST',
          payload,
      );
      if (response.ok) {
        fetchReports();
      } else {
        console.error('Error:', response.status);
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

    useEffect(() => {
    const fetchUser = async () => {
      try {
        const response = await makeAuthenticatedRequest(
          `${process.env.REACT_APP_API_HOST}/user/me/`
        );
        if (response.ok) {
          const jsonData = await response.json();
          const totalReportCounts = {
            FREE: 1,
            PREMIUM: 5,
            PRO: 999,
          }
          setReportTotal(totalReportCounts[jsonData.tier])
        } else {
          // Handle error if the response is not ok
          console.error('Error:', response.status);
        }
      } catch (error) {
        // Handle error if the request fails
        console.error('Error:', error);
      }
    };

    fetchUser();
  }, []);

  function getNextSaturdayMidday() {
    const now = new Date();

    // Check if it's Saturday and past midday
    if (now.getDay() === 6 && now.getHours() >= 12) {
      now.setDate(now.getDate() + 7); // Add 7 days to get to the next Saturday
    } else {
      const daysUntilNextSaturday = 6 - now.getDay(); // 6 corresponds to Saturday
      now.setDate(now.getDate() + daysUntilNextSaturday);
    }

    now.setHours(12, 0, 0, 0); // Set time to midday
    return now;
  }

  const handleCheckboxChange = (event, selectedReport) => {
    if ((reportCount >= reportTotal) && !selectedReport.active) {
      window.location.href = '/premium';
      return
    }
    handleToggleActiveReport(selectedReport);
  };

    const handleToggleActiveReport = async (selectedReport) => {
    try {
      const payload = {
        active: selectedReport.active,
      };
      const response = await makeAuthenticatedRequest(
        `${process.env.REACT_APP_API_HOST}/report/${selectedReport.uuid}/toggle_active/`,
        'POST',
          payload,
      );
      if (response.ok) {
        fetchReports();
      } else {
        console.error('Error:', response.status);
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  // useEffect(() => {
  //   const activeReportCount = reports.filter(report => report.active).length;
  //
  //   setReportCount(activeReportCount);
  // }, [reports]);

  return (
    <RootContainer>
        <MainAppBar currentPage="Dashboard"/>
      <StyledContainer maxWidth="lg" className="mapContainer">
        <Box>
          <Paper>
            <IrelandMap highlightedCounties={reports.map((item) => (item.zone))} selectedCounty={expanded.zone} />
          </Paper>
        </Box>
      </StyledContainer>
      <div style={{ "maxHeight": "10vh" }}>
        <StyledContainer style={{ display: "flex", maxWidth: "lg" }}>
          <ReportCount
                owns={activeReportsOpen ? 'active-reports-popover' : undefined}
                hasPopup="true"
                onMouseEnter={handleActiveReportsPopoverOpen}
                onMouseLeave={handleActiveReportsPopoverClose}
                count={reportCount}
                total={reportTotal}
            />
          <Popover
            id="active-reports-popover"
            sx={{
              pointerEvents: 'none',
            }}
            open={activeReportsOpen}
            anchorEl={activeReportsPopover}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            onClose={handleActiveReportsPopoverClose}
            disableRestoreFocus
          >
            <Typography sx={{ p: 1 }}>The amount of active reports that you can currently have. Click to unlock more</Typography>
          </Popover>

          <CountdownTimer
              owns={countdownOpen ? 'countdown-popover' : undefined}
              hasPopup="true"
              onMouseEnter={handleCountdownPopoverOpen}
              onMouseLeave={handleCountdownPopoverClose}
              targetDate={getNextSaturdayMidday()}
          />
                    <Popover
            id="countdown-popover"
            sx={{
              pointerEvents: 'none',
            }}
            open={countdownOpen}
            anchorEl={countdownPopover}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            onClose={handleCountdownPopoverClose}
            disableRestoreFocus
          >
            <Typography sx={{ p: 1 }}>The countdown to the next reports release. All active reports will be sent to your email</Typography>
          </Popover>
        </StyledContainer>
      </div>
      {/*<div className={classes.content}>*/}
        <StyledContainer maxWidth="lg">
          <Box sx={{ width: '100%' }}>
            <StyledAccordionContainer>
              {reports.map((report) => (
                <StyledAccordion
                  key={report.uuid}
                  expanded={expanded.uuid === report.uuid}
                  onChange={handleChange(report)}
                >
                  <StyledAccordionSummary expandIcon={<ExpandMoreIcon />} aria-label="view">
                    <Grid container alignItems="center" justifyContent="space-between">
                      <Grid item style={{ flex: 1 }}>
                        <Checkbox
                          checked={report.active}
                          onChange={(event) => handleCheckboxChange(event, report)}
                          color="primary"
                          aria-owns={checkboxOpen ? 'checkbox-popover' : undefined}
                          aria-haspopup="true"
                          onMouseEnter={handleCheckboxPopoverOpen}
                          onMouseLeave={handleCheckboxPopoverClose}
                        />
                        <Popover
                          id="checkbox-popover"
                          sx={{
                            pointerEvents: 'none',
                          }}
                          open={checkboxOpen}
                          anchorEl={checkboxPopover}
                          anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                          }}
                          transformOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                          }}
                          onClose={handleCheckboxPopoverClose}
                          disableRestoreFocus
                        >
                          <Typography sx={{ p: 1 }}>Activate report so you can receive it weekly</Typography>
                        </Popover>
                        <StyledChip label={report.category} variant="outlined" />
                        <StyledChip label={report.tier} variant="outlined" />
                      </Grid>
                      <Grid item style={{ flex: 1 }}>
                        <Typography style={{ textAlign: 'center' }}>{report.zone_type === "COUNTY" ? (`${report.zone_type} ${report.zone}`) : (`${report.zone } ${report.zone_type}`)}</Typography>
                      </Grid>
                      <Grid item style={{ flex: 1 }}></Grid>
                    </Grid>
                  </StyledAccordionSummary>
                  <AccordionDetailsContainer>
                    <Typography variant="body2">
                      {reportHistories
                        .filter((reportHistory) => reportHistory.report.uuid === report.uuid)
                        .map((reportHistory) => (
                          <StyledButton
                            key={reportHistory.uuid}
                            component={Link}
                            to={`/report-history/${reportHistory.uuid}`}
                            variant="contained"
                            style={{ margin: '10px' }}
                          >
                            {reportHistory.date}
                          </StyledButton>
                        ))}
                    </Typography>
                    <StyledIconButton onClick={handleUnsubscribe} style={{ marginLeft: 'auto' }}>
                      <DeleteIcon />
                    </StyledIconButton>
                  </AccordionDetailsContainer>
                </StyledAccordion>
              ))}
              <Grid container justifyContent="center">
                <Grid item>
                  <SubscribeReport fetchReports={fetchReports} reports={reports} />
                </Grid>
              </Grid>
            </StyledAccordionContainer>
          </Box>
        </StyledContainer>
      {/*</div>*/}
    </RootContainer>
  );
};

export default Home;

import React from "react";
import Typography from "@mui/material/Typography";
import {StyledLink} from "../styles/link";

const ReportCount = ({count, total, onMouseEnter, onMouseLeave, owns, hasPopup}) => {
  return (
    <StyledLink href="/premium" variant="h10" onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave} aria-owns={owns} aria-haspopup={hasPopup} className="show-report-count">
      <Typography variant="h10">
        {count}/{total}
      </Typography>
    </StyledLink>
  );
};

export default ReportCount;
import React, {Fragment, useEffect, useState} from 'react';
import {styled} from '@mui/system';
import {Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Select,} from '@mui/material';
import IconButton from "@mui/material/IconButton";
import AddIcon from "@mui/icons-material/Add";
import {makeAuthenticatedRequest} from '../../utils/authenticated_request';
import {StyledButton, StyledIconButton} from "../styles/button";

const StyledContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  marginTop: theme.spacing(4),
}));

const StyledFormControl = styled(FormControl)(({ theme }) => ({
  width: '100%',
  marginTop: theme.spacing(2),
}));

const SubscribeReport = ({ fetchReports }) => {
  const [user, setUser] = useState({});
  const [zones, setZones] = useState({ cities: [], counties: [] });
  const [open, setOpen] = useState(false);

  const [category, setCategory] = useState("");
  const [location, setLocation] = useState("");

  const handleCategoryChange = (event) => {
    setCategory(event.target.value);
  };

  const handleLocationChange = (event) => {
    setLocation(event.target.value);
  };

  const handleOpenSubscribe = () => {
    setOpen(true);
  };

  const handleCloseSubscribe = () => {
    setOpen(false);
  };

  const handleSubmitSubscribe = async () => {
    handleCloseSubscribe()
    const array_location = location.split(" ");
    const payload = {
      category: category,
      zone_type: array_location[0],
      zone: array_location[1],
    };
    try {
      const response = await makeAuthenticatedRequest(
        `${process.env.REACT_APP_API_HOST}/report/subscribe/`,
        'POST',
        payload
      );
      if (response.ok) {
        fetchReports()
      } else {
        // Handle error if the response is not ok
        console.error('Error:', response.status);
      }
    } catch (error) {
      // Handle error if the request fails
      console.error('Error:', error);
    }
  };

  useEffect(() => {
    setLocation("");
    const fetchZones = async () => {
      try {
        const response = await makeAuthenticatedRequest(
          `${process.env.REACT_APP_API_HOST}/report/zones/?category=${category}`,
        );
        if (response.ok) {
          const jsonData = await response.json();
          setZones(jsonData);
        } else {
          // Handle error if the response is not ok
          console.error('Error:', response.status);
        }
      } catch (error) {
        // Handle error if the request fails
        console.error('Error:', error);
      }
    };

    fetchZones();
  }, [category]);

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const response = await makeAuthenticatedRequest(
          `${process.env.REACT_APP_API_HOST}/user/me/`
        );
        if (response.ok) {
          const jsonData = await response.json();
          setUser(jsonData);
        } else {
          // Handle error if the response is not ok
          console.error('Error:', response.status);
        }
      } catch (error) {
        // Handle error if the request fails
        console.error('Error:', error);
      }
    };

    fetchUser();
  }, []);

  return (
    <Fragment>
      <StyledIconButton onClick={handleOpenSubscribe}>
        <AddIcon />
      </StyledIconButton>
      <Dialog open={open} onClose={handleCloseSubscribe}>
        <DialogTitle>Create a new report</DialogTitle>
        <DialogContent>
          <StyledContainer>
            <StyledFormControl>
              <Select
                native
                value={category}
                onChange={handleCategoryChange}
              >
                <option value={""}>Select a Category</option>
                <option value={"BUY"}>Buy</option>
                <option value={"RENT"}>Rent</option>
              </Select>
            </StyledFormControl>
            <StyledFormControl>
              <Select
                native
                value={location}
                onChange={handleLocationChange}
              >
                <option value={""}>Select a Location</option>
                <optgroup label="Cities">
                  {zones.cities.map((city) => (
                    <option key={city[0]} value={`CITY ${city[0]}`}>
                      {city[1]}
                    </option>
                  ))}
                </optgroup>
                <optgroup label="Counties">
                  {zones.counties.map((county) => (
                    <option key={county[0]} value={`COUNTY ${county[0]}`}>
                      {county[1]}
                    </option>
                  ))}
                </optgroup>
              </Select>
            </StyledFormControl>
          </StyledContainer>
        </DialogContent>
        <DialogActions>
          <StyledButton onClick={handleCloseSubscribe}>
            Cancel
          </StyledButton>
          <StyledButton
            disabled={!category || !location}
            onClick={handleSubmitSubscribe}
            variant="contained"
            color="primary"
          >
            Save
          </StyledButton>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
};

export default SubscribeReport;

import {styled} from "@mui/system";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";

export const StyledButton = styled(Button)(({ theme }) =>({
  background: theme.palette.secondary.main,
  color: "#ffffff",
}));

// export const StyledIconButton = styled(Button)(({ theme }) =>({
//   fill: "#ffffff",
// }));

export const StyledIconButton = styled(IconButton)(({ theme }) =>({
  backgroundColor: theme.palette.secondary.main,
  fill: "black"
}));
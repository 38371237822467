import React from 'react';
import {ComposableMap, Geographies, Geography} from 'react-simple-maps';
import irelandCounties from '../../resources/IrelandMap.geojson';
import {StyledMapContainer} from "../styles/container";
import theme from "../../theme";

const mapColors = (isHighlighted, isSelected) => {
    if (isSelected) {
        return "#F6131E"

    }
    else if (isHighlighted) {
        return theme.palette.secondary.main
    }
    else {
        return theme.palette.primary.main
    }
}
const IrelandMap = ({ highlightedCounties, selectedCounty }) => {
  return (
      <StyledMapContainer>

    <ComposableMap projection="geoMercator" projectionConfig={{
        scale: 5000,
        center: [-7.5, 53.5]
    }}
            style={{ height: '100%', width: '100%' }}>
      <Geographies geography={irelandCounties}>
        {({ geographies }) =>
          geographies.map(geo => {
            const countyName = geo.properties.ENGLISH; // Modify the property name according to your GeoJSON
            const isHighlighted = highlightedCounties.includes(countyName);
            const isSelected = selectedCounty === countyName
            return (
              <Geography
                key={geo.rsmKey}
                geography={geo}
                fill={mapColors(isHighlighted, isSelected)}
                stroke={"#ffffff"}
                strokeWidth={1}
                className="custom-geography"

              />
            );
          })
        }
      </Geographies>
    </ComposableMap>
      </StyledMapContainer>
  );
};


export default IrelandMap;
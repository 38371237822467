import React, {useEffect, useState} from 'react';
import {makeAuthenticatedRequest} from "../../utils/authenticated_request";
import {Link} from 'react-router-dom';
import Logout from "../authentication/Logout";
import {Typography} from "@mui/material";
import {StyledButton} from "../styles/button";


const ReportHistories = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchReportHistories = async () => {
      try {
        const response = await makeAuthenticatedRequest(`${process.env.REACT_APP_API_HOST}/report_history/`);
        if (response.ok) {
          const jsonData = await response.json();
          setData(jsonData.results);
        } else {
          // Handle error if the response is not ok
          console.error('Error:', response.status);
        }
      } catch (error) {
        // Handle error if the request fails
        console.error('Error:', error);
      }
    };

    fetchReportHistories();

  }, []);

  return (
    <div>
      <Typography variant="h1" component="h1" gutterBottom>
        Report Histories
      </Typography>
      {data.map((item) => (
        <StyledButton
          key={item.uuid}
          component={Link}
          to={`/report-history/${item.uuid}`}
          variant="contained"
          style={{ margin: '10px' }}
        >
          {item.date}
        </StyledButton>
      ))}
      <Logout />
    </div>
  );
};

export default ReportHistories;
